@use 'bootstrap/scss/bootstrap';
@import 'primeicons/primeicons.css';

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
  font-family: CaixaBold;
  src: url(assets/fonts/CAIXAStd-Bold.ttf) format('truetype'),
    url(assets/fonts/FuturaStdBold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: CaixaMedium;
  src: url(assets/fonts/CAIXAStd-Regular.ttf) format('truetype'),
    url(assets/fonts/FuturaStdMedium.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: CaixaStdBook;
  src: url(assets/fonts/CAIXAStd-Book.ttf) format('truetype'),
    url(assets/fonts/FuturaStdBook.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: CaixaItalic;
  src: url(assets/fonts/CAIXAStd-Italic.ttf) format('truetype');
  font-display: swap;
}
.p-multiselect-panel {
  .p-multiselect-header {
    display: flex !important;
    justify-content: flex-end !important;
   }
}
.p-multiselect .p-multiselect-label {
  padding-left: 0;
}
* {
  transition: 0.2s ease-in-out;
}
a {
  cursor: pointer;
}
a.link-orange {
  color: #ff9400 !important;
}
.title-block-text {
  font-size: 14px !important;
  color: #3c3c3c;
  font-weight: bold;
}
.font-black{
  color: #3c3c3c !important;
}

.body-json {
  p,
  li {
    line-height: 30px !important;
  }
  li {
    margin-bottom: 1rem;
  }
  strong,
  .font-caixa {
    color: #0060af !important;
  }
  p {
    margin-bottom: 2rem;
  }
  h2,
  h1 {
    margin: 3.5rem 0 !important;
    color: #0060af !important;
  }
  h4,
  h4,
  h3,
  h5,
  h6 {
    margin-top: 3rem !important;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.6rem !important;
    }
    h2 {
      font-size: 1.375rem !important;
    }
  }
}
.color-green {
  color: #b6cd13;
}

.orange-font {
  color: #ff9400 !important;
}
.text-md {
  font-size: 24px;
}
body {
  font-family: 'CaixaMedium', sans-serif;
  font-size: 18px;
  color: #7c7c7c;
  font-weight: 400;
  line-height: normal;
  // line-height: 1.11111rem;
  overflow-x: hidden;
}
.disclaimer {
  font-size: 14px;
  color: rgb(163, 163, 163);
}
.text-blue {
  color: #005faf !important;
}
.not-scroll {
  overflow-y: hidden !important;
}
a.btn {
  color: #fff;

  &:hover {
    color: #ff9400 !important;
    background: #fff !important;
  }
}
#fade-header {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(92, 91, 91, 0.6);
  z-index: 1001;
}

.box-bd-vouchers {
  padding: 1rem;
  border: 1px solid #dfdddd;
  height: 85px;
  width: 85px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bd-vouchers img {
  max-width: 55px;
}
.filter-invert {
  max-width: 55px;
  filter: brightness(0.1);
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  text-align: center;
  padding: 7px 20px;
  border: 1.5px solid #ff9400;
  background-color: #ff9400;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #fff;
  color: #ff9400 !important;
  border-color: #ff9400;
}

.btn-big {
  min-width: 200px;
}
.btn-cta {
  background: #ffff !important;
  border: 1.5px solid #ffff;
  color: #006bae !important;
  cursor: pointer;
}
.btn-cta:hover {
  background: #fffffff5 !important;
  border: 1.5px solid #ffff;
}
.span-json {
  font-size: 14px;
  color: #7c7c7c;
  margin-bottom: 1rem;
}

html h1,
body h1 {
  font-size: 28px;
}

html h1,
body h1 {
  font-family: 'CaixaBold', sans-serif;
  font-size: 35px;
  line-height: 1.1em;
}

html h1,
html h2,
body h1,
body h2 {
  font-weight: 700;
}

html .title,
body .title {
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  color: #272727;
}

.fonte-azul {
  color: #005faf;
}

a:hover {
  color: #002745;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

.text-white {
  color: #fff !important;
}

.mx-100 {
  max-width: 100% !important;
}

.titulo-laranja {
  color: #ff9400;
}

.default-section {
  margin-top: 75px;
  margin-bottom: 75px;
}

.default-section .content {
  padding: 60px 0px;
}

.section-grey .content {
  background-color: #f3f3f3;
}

.pr-10 {
  padding-right: 12.5%;
}

.text-grey {
  color: #727272;
}

.btn-big {
  min-width: 200px;
}

.btn-full-width {
  width: 100%;
}

html h3,
body h3 {
  font-size: 19px;
  line-height: 1em;
}

html h5,
body h5 {
  color: #404040;
}

html .futura-std-book,
body .futura-std-book {
  font-family: 'CaixaStdBook', sans-serif;
}

html h1.futura-std-book,
body h1.futura-std-book {
  font-size: 50px;
}

.titulo {
  margin-top: 20px;
  padding-right: 5vw;
}

.single-solucao {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.texto-solucao {
  color: #727272;
  font-size: 20px;
  margin-left: 15px;
  line-height: 1.1em;
}

.banner-area {
  max-width: 1920px;
  margin: auto;
  // background-color: #005faf;
}
.faq-taxas {
  display: none;
}
@media (min-width: 800px) {
  .banner-area {
    // background-position-y: 4rem !important;
  }
  .md-flex-col{
    flex-direction: column;
    display: flex;
  }
}
@media (max-width: 800px) {
  body {
    .imagem-banner,
    .banner-area,
    .banner-interno {
      // background-position-y: 4rem !important;
      // min-height: 750px !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      .text-area,
      .area-text,
      .titulo-banner {
        padding-bottom: 0rem !important;
        max-width: 100% !important;
        margin-bottom: 2vh !important;
      }
    }
  }
  .faq-taxas {
    display: block;
    .container-taxas {
      padding-inline: 1rem;
      header {
        background: #f5a436;
        color: #000;
        padding: 5px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
      }
      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;

        span {
          color: #0060af;
        }
      }
    }
  }
}

.integradoras-modal {
  a {
    display: inline-block !important;
  }
  .tel{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
